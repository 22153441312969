import React from 'react';
import { Col, Row } from 'react-grid-system';
import Typography from '../components/atoms/typography';
import Spacer from '../components/atoms/spacer';
import styled from 'styled-components';
import { panBoxUrl } from '../constants';
import DocumentTemplate from '../components/templates/document';
import SEO from '../components/seo';

const BoldSpan = styled(Typography)`
  font-weight: bold;
`;

const CookiePolicy = () => {
  return (
    <>
      <SEO title="Cookie policy" />
      <DocumentTemplate title="Cookie Policy">
        <Row>
          <Col md={6}>
            <Typography variant="h4">COOKIEPOLICY FÖR PANBOX</Typography>
            <Spacer />
            <Typography>
              Denna hemsida{' '}
              <Typography
                color="primary"
                variant="external-link"
                href={panBoxUrl}
              >
                www.panbox.se
              </Typography>{' '}
              tillhandahålls av PB Services AB (“Panbox”) org.nr. 559271-7408.
            </Typography>
            <Spacer />
            <Typography>
              För att tillförsäkra denna webbsidas funktionalitet använder sig
              PanBox av små textfiler, webbsändare och liknande tekniker
              (gemensamt benämnt “Cookies”). Denna Cookiepolicy förklarar vad
              cookies är, varför de används och dina alternativ angående deras
              användning. För att lära dig mer om hur vi hanterar dina
              personuppgifter som samlas in genom cookies, läs vår
              Dataskyddspolicy.
            </Typography>
            <Spacer />
            <Typography>
              Godkännande av vår användning av cookies är valfritt och du kan
              när som helst välja att dra tillbaka ditt samtycke. Vänligen se
              mer information under avsnittet ”Hur kontrollerar du användningen
              av cookies”.
            </Typography>
            <Spacer />
            <Typography variant="h4">1. VAD ÄR COOKIES?</Typography>
            <Spacer />
            <Typography>
              Cookies är små textfiler som skickas till din dator eller mobila
              enhet när du besöker en webbplats. Cookies kan lagra
              användarinställningar och annan information för att förbättra din
              upplevelse när du interagerar med webbplatsen och våra tjänster.
              Cookies skickas från Panbox eller våra partners webbservrar och
              lagras på din enhet enligt beskrivningen nedan. Vi använder
              följande cookies:
            </Typography>
            <Spacer />
            <Typography>
              <BoldSpan variant="span">Sessioncookies:</BoldSpan> Tillfälliga
              cookie-filer som upphör att gälla när du avslutar din webbläsare.
            </Typography>
            <Spacer />
            <Typography>
              <BoldSpan variant="span">Ihållande cookies:</BoldSpan> Cookiefiler
              som finns kvar tills du raderar dem eller de löper ut.
            </Typography>
            <Spacer />
            <Typography>
              <BoldSpan variant="span">Förstapartscookies:</BoldSpan> Cookies
              som ställts in av webbplatsen du besöker. Bara den webbplatsen kan
              läsa dem.
            </Typography>
            <Spacer />
            <Typography>
              <BoldSpan variant="span">Tredjepartscookies: </BoldSpan> Cookies
              från en tredjepartswebbplats. Exempelvis om webbplatsen du besöker
              använder externa tjänster från en tredje part, kan den tredje
              parten också ställa in sina egna cookies om tredjepartscookies
              används och tillåts.
            </Typography>
            <Spacer />
            <Typography variant="h4">2. VARFÖR ANVÄNDER VI COOKIES?</Typography>
            <Spacer />
            <Typography>
              Några av funktionerna på webbplatsen behöver cookies för att
              fungera. Andra cookies finns för att förbättra din upplevelse och
              för att vi ska förstå vilken typ av kommunikation du som besökare
              uppskattar eller för att analysera hur vår webbplats används. I
              allmänhet kategoriserar vi våra cookies och deras användning
              enligt beskrivningen nedan.
            </Typography>
            <Spacer />
            <Typography>
              <BoldSpan variant="span"> Nödvändiga cookies: </BoldSpan> Cookies
              som är nödvändiga för att kunna tillhandahålla den tjänst du har
              begärt.
            </Typography>
            <Spacer />
            <Typography>
              <BoldSpan variant="span">Funktionella cookies: </BoldSpan> Cookies
              som används för att aktivera webbplatsens funktioner.
            </Typography>
            <Spacer />
            <Typography>
              <BoldSpan variant="span"> Marknadsföringscookies: </BoldSpan>
              Cookies som används för att visa annonser och rekommendationer på
              webbplatser (men också för att begränsa antalet gånger en viss
              annons visas). Dessa typer av cookies kräver samtycke och du kan
              när som helst vägra att ge ditt samtycke eller återkalla ditt
              samtycke.
            </Typography>
            <Spacer />
            <Typography>
              <BoldSpan variant="span">Statistikcookies: </BoldSpan>Cookies som
              bedömer hur du interagerar med vår webbplats som anonym användare.
              Dessa cookies används för att samla in och analysera statistik,
              exempelvis historik över ditt besök, dina klick, och vad du öppnat
              och läst.
            </Typography>
          </Col>
          <Col md={6}>
            <Typography>
              Vi använder analysverktyg från Google Analytics och Facebook Pixel
              som hjälper oss att förstå hur besökare använder webbplatsen samt
              för att utvärdera hur vi kan utveckla och förbättra webbplatsen.
              Det gör det också möjligt för oss att hitta nya kunder eller
              personer som har besökt en viss sida eller utfört en viss åtgärd
              på vår webbplats och se till att våra annonser visas för dem samt
              att mäta användarnas reaktioner på våra annonser. Användningen av
              dessa verktyg innebär att vi låter verktygen hämta information om
              ditt besök på webbplatsen. Google LLC och Facebook är ansvariga
              (dataansvariga) för sin egen användning av cookies och dina
              personuppgifter, besök deras webbplatser{' '}
              <Typography
                color="primary"
                variant="external-link"
                href="https://www.facebook.com/"
              >
                www.facebook.com
              </Typography>{' '}
              och{' '}
              <Typography
                color="primary"
                variant="external-link"
                href="https://www.google.com/"
              >
                www.google.com
              </Typography>{' '}
              för att läsa mer.
            </Typography>
            <Spacer />
            <Typography>
              Med undantag för de cookies som är absolut nödvändiga för
              webbplatsens funktion eller för att tillhandahålla den tjänst som
              du begär på webbplatsen måste vi inhämta ditt samtycke innan vi
              börjar använda cookies. Du har rätt att när som helst vägra att ge
              ditt samtycke eller återkalla ditt samtycke.
            </Typography>
            <Spacer />
            <Typography variant="h4">3. VILKA COOKIES ANVÄNDER VI?</Typography>
            {/* <Spacer />
            <Typography>
              Panbox hemsida använder mestadels ”förstapartscookies”. Dessa är
              cookies som ställs in och kontrolleras av Panbox och inte av någon
              tredje part. Kolumnen ”Cookietyp” i tabellen nedan visar vilken
              part som har ställt in cookien. Om en tredje part ställt in
              cookien kommer deras cookiepolicy att vara gällande.
            </Typography>
            <Spacer />
            <Typography>Name Cookie type Purpose Expiry</Typography> */}
            <Spacer />
            <Typography variant="h4">
              4. HUR KONTROLLERAR DU ANVÄNDNINGEN AV COOKIES?
            </Typography>
            <Spacer />
            <Typography>
              <BoldSpan variant="span">
                Ta bort cookies från din enhet:
              </BoldSpan>{' '}
              Du kan radera alla cookies som redan finns på din enhet genom att
              rensa webbläsarens historik. Detta kommer att ta bort alla cookies
              från alla webbplatser du har besökt. Tänk dock på att du kan
              förlora viss sparad information (t.ex. sparade
              inloggningsuppgifter, webbplatsinställningar).
            </Typography>
            <Spacer />
            <Typography>
              <BoldSpan variant="span">
                Hantera platsspecifika cookies:
              </BoldSpan>{' '}
              För mer detaljerad kontroll över platsspecifika cookies,
              kontrollera sekretess och cookie-inställningar i din webbläsare.
            </Typography>
            <Spacer />
            <Typography>
              <BoldSpan variant="span">Blockering av cookies:</BoldSpan> Du kan
              ställa in din webbläsare så att inga cookies placeras på din
              enhet, men du kan då behöva justera vissa inställningar manuellt
              varje gång du besöker en webbplats, och vissa tjänster och
              funktioner kanske inte fungerar korrekt alls. Du kan också ställa
              in din webbläsare så att den endast accepterar förstapartscookies.
            </Typography>
            <Spacer />
            <Typography variant="h4">
              5. HUR OFTA UPPDATERAS DENNA COOKIEPOLICY?
            </Typography>
            <Spacer />
            <Typography>
              Vi kan komma att uppdatera denna cookiepolicy från tid till annan
              för att återspegla ändringar i de cookies som vi använder eller av
              andra juridiska eller regulatoriska skäl. Besök denna cookiepolicy
              regelbundet för att hålla dig informerad om vår användning av
              cookies och relaterad teknik.
            </Typography>
            <Typography>
              Om du har några frågor om denna cookiepolicy, skicka ett
              e-postmeddelande till Panbox på{' '}
              <Typography
                color="primary"
                variant="external-link"
                href="mailto: kundservice@panbox.se"
              >
                kundservice@panbox.se
              </Typography>
            </Typography>
          </Col>
        </Row>
      </DocumentTemplate>
    </>
  );
};

export default CookiePolicy;
